<template xmlns="http://www.w3.org/1999/html">
  <div class="content palm-bg">
    <div class="row" style="background: rgba(45,91,88,0.4);">
      <article class="col-xl-12 col-sm-12">
        <div
          class="centered"
          style="width: 100%; "
          :style="{
              background: `linear-gradient(${secondaryColor}, black)`,
            }">


          <div style="z-index: 1">

            <img
              v-lazy="
              mainImage +
              '?w=315&h=200&fit=fillmax&fill=blur&auto=format'
            "
              style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
              border-radius: 0;
            "
              alt=" TULUM PARTY BLOG"
              width="315px"
              height="200px"
              class=" d-inline-xs d-sm-none"

            />

            <img
              v-lazy="
              mainImage +
              '?h=300&w=615&fit=fillmax&fill=blur&auto=format'
            "
              style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0);
              border-radius: 0;
            "
              alt=" TULUM PARTY BLOG"
              width="615px"
              class=" d-none d-sm-inline"
              height="300px"
            />
            <h1
              class="tulum-party-title text-left"
              style="
             line-height: 1.2;
             text-transform: capitalize !important;
             text-wrap: balance;
                margin: 20px;
                font-size: 1.8rem;
                padding: 20px;
              "
            >
              PARTY BLOG
            </h1>

            <div class="text-left" style="margin: 20px;  padding: 20px;margin-top:-60px;">
              <p class="tulum-party-title "
                 style="font-size: 0.8rem; font-weight: normal;text-transform:uppercase">
                TULUM DECODED</p>

              <hr>
              <p style="background-color: #0e0e0e !important;">Sit back, relax, and embark on a reading experience that
                will open your eyes to the <strong>vibrant,
                  wondering, and amazing place that is Tulum, Mexico.</strong></p>


              <hr>
              <p>

                This comprehensive set of articles provides a detailed exploration of Tulum's dynamic party scene, offering insights into the most sought-after venues, events, and essential tips for optimizing your visit. Whether you're a seasoned party-goer or seeking a memorable introduction to Tulum's nightlife, this resource is designed to equip you with the necessary information to curate an exceptional vacation experience.
              </p>
            </div>
          </div>


          <br>
        </div>

        <hr>

        <section class="text-justify">
          <h3 class="section-header">ENTRIES</h3>
        </section>

        <div id="articles" class="row">
          <div v-for="(article, idx) in articles"
               class="col-md-6 col-sm-12 card card-animation-on-hover-no-bg "
               :key="idx"
          >

            <div
              class="centered"
              style="width: 100%; "
              :style="{
              background: `linear-gradient(${secondaryColor}, black)`,
            }">

              <router-link :to="{path: '/article/'+ article.slug}">

                <img
                  v-lazy="
              article.metadata.cover.imgix_url +
              '?w=315&h=200&fit=fillmax&fill=blur&auto=format'
            "
                  style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgba(0 0 0, 0.6);
              border-radius: 0;
            "
                  alt=" TULUM PARTY BLOG"
                  width="315px"
                  height="200px"
                  class=" d-inline-xs d-sm-none"

                />

                <img
                  v-lazy="
              article.metadata.cover.imgix_url +
              '?h=300&w=615&fit=fillmax&fill=blur&auto=format'
            "
                  style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgba(0 0 0, 0.6);
              border-radius: 0;
            "
                  alt=" TULUM PARTY BLOG"
                  width="615px"
                  class=" d-none d-sm-inline"
                  height="300px"
                />
                <h3 class="section-header text-left" style="text-transform: capitalize;font-size: 1.1rem">
                  {{ article.title }}</h3>
                <p style="padding:10px;margin-top: -50px;font-size: 11px;text-transform: capitalize;font-weight: lighter">{{ article.metadata.caption }}</p>
                <div class="card" >
                  <small><i class="tim-icons icon-calendar-60"
                            :style="{color: secondaryColor, borderRadius: '100px', padding: '5px'}"></i>
                   {{new Date(article.created_at).toDateString() }}</small>
                  <small><i class="tim-icons icon-pencil"
                            :style="{color: secondaryColor, borderRadius: '100px', padding: '5px'}"></i>
                    {{new Date(article.published_at).toDateString() }} </small>
                </div>
                <h5 class="section-header">
                  <i class="tim-icons icon-light-3"
                     style="font-size: 12px"
                     :style="{color: secondaryColor, border: `2px solid ${secondaryColor}`, borderRadius: '100px', padding: '5px'}"></i>
                  SUMMARY</h5>
                <div v-html="article.metadata.summary"></div>

                <br>
              </router-link>
            </div>
          </div>

        </div>

        <hr>
        <p>Showing {{ (val > 1 ? pageSize + 1 : 1) }} - {{ articles ? articles.length + (val > 1 ? pageSize : 0) : 0 }}
          of {{ total }} ARTICLE | <a style="display: inline-block" class="btn btn-link" @click="showAll"
                                      v-if="!showingAll">Show all ({{ total }})</a></p>

        <BasePagination type="primary" :per-page="pageSize" :total="total" :value="val" v-if="!showingAll"
                        :nextPage="nextPage" :prevPage="prevPage" :changePage="changePage"></BasePagination>


        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="TULUM.PARTY">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <hr>
              <p>
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>

      </article>

    </div>


  </div>


</template>

<script>
import BasePagination from '../../components/BasePagination.vue';
import ImgCard from "@/components/Cards/ImgCard";
import SmartMenu from "@/components/SmartMenu/SmartMenu";

export default {
  name: 'Blog',
  metaInfo() {
    return {
      title: `TULUM.PARTY BLOG`,
      meta: [
        {
          name: 'description',
          content: `Tulum Articles`,
        },
        {
          name: 'keywords',
          content: `Tulum Party Blog, Articles, Nightlife, Tulum Scene, Travel Recommendations`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
      table: [],
      val: undefined,
      secondaryColor: '#776767',
      mainImage: 'https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg',
      showingAll: false,
      showPalms: false,
      pageSize: 30,
      page: 0,
      showModal: false,
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
    };
  },
  components: {
    ImgCard,
    BasePagination,
    SmartMenu
  },
  created: function () {
    // this.$store.dispatch('getArtists');
    this.$store.dispatch('getArticles', {start: 0, limit: this.pageSize});
    this.$store.dispatch('getLinks', {slug: 'blog'});


  },
  methods: {
    changePage: function (page) {
      this.page = page;
      this.val = this.page;
      this.$store.dispatch('getArticles', {start: (this.page * this.pageSize), limit: this.pageSize});

    },
    nextPage: function () {
      this.page++;
      this.val = this.page;
      this.$store.dispatch('getArticles', {start: (this.page * this.pageSize), limit: this.pageSize});
    },
    showAll: function () {
      this.$store.dispatch('getArticles', {start: 0, limit: this.total});
      this.showingAll = true;

    },
    prevPage: function () {
      this.page--;
      this.val = this.page;

      this.$store.dispatch('getArticles', {start: (this.page * 10), limit: this.pageSize});
    }
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    articles: {
      get() {
        return this.$store.state.articles;
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
  },
};
</script>

